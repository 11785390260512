import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Slider from "react-slick";
import "../themes/slick.css";
import "../themes/slick-theme.css";

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 8000,
};

class CarouselParks extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            image1: file(relativePath: { eq: "my-image-2-path.JPG" }) {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            image2: file(relativePath: { eq: "my-image-1-path.JPG" }) {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            image3: file(relativePath: { eq: "my-image-3-path.JPG" }) {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            image4: file(relativePath: { eq: "my-image-4-path.JPG" }) {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={(data) => (
          <Slider {...settings}>
            <Img fluid={data.image1.childImageSharp.fluid} />
            <Img fluid={data.image4.childImageSharp.fluid} />
            <Img fluid={data.image2.childImageSharp.fluid} />
            <Img fluid={data.image3.childImageSharp.fluid} />
          </Slider>
        )}
      />
    );
  }
}

export default CarouselParks;
