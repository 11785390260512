import React from "react";
import { Link } from "gatsby"

// import Layout from "../components/layout.jsx"
// import SEO from "../components/seo"
import stadiumsFile from "../data/Stadiums_Local_Businesses.pdf";
import fmcFile from "../data/15_Minutes.pdf";
import sslFile from "../data/Third_Places_Stockholm.pdf";
import cityChangeFile from "../data/City_Change.pdf";
import parksDiversityFile from "../data/NYC_Parks_Diversity.pdf";
import parksSocialTiesFile from "../data/Parks_Social_Ties_Online.pdf";
import { MdPictureAsPdf } from "react-icons/md";
import { GrDocumentPdf } from "react-icons/gr";
import { VscFilePdf } from "react-icons/vsc";
import Img from "gatsby-image";
import CarouselParks from "../components/carousel";
import Image from "../components/image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import SEO from "../components/seo";

import { Box, Flex } from "rebass";
import _ from "lodash";
import { useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
// import { faFilePdf } from "@fortawesome/free-regular-svg-icons"

// import { ThemeProvider } from 'emotion-theming'
// import theme from '../themes/theme.js'

const textSizes = ["15px", "15px", "16px"];
const headTextSizes = ["20px", "28px", "32px"];
const smallTextSizes = ["14px", "14px", "16px"];
const tagTextSizes = ["12px", "12px", "14px"];

const titleTextSizes = ["18px", "20px", "20px"];

function AuthorItems({ authors }) {
  return _.map(authors, (author) => (
    <Box
      sx={{
        ml: 3,
        py: "6px",
        px: "9px",
        color: "black",
        fontFamily: "Roboto Condensed, sans-serif",
        fontWeight: "light",
        borderStyle: "outset",
        borderColor: "white",
        borderWidth: "1px",
        // bg: "#E8F6E0",
        // borderStyle: 'solid',
        // borderColor: 'plainDark',
        // borderWidth: "1px",
        fontSize: tagTextSizes,
        // ":hover": {
        //   color: "greeDark",
        // },
        display: "inline-block",
      }}
    >
      <a
        href={author.link}
        target="_blank"
        style={{ color: "inherit", textDecoration: "none" }}
      >
        with {author.name}
      </a>
    </Box>
  ));
}

function PaperTags({ tags }) {
  return _.map(tags, (tag) => (
    <Box
      sx={{
        ml: [2, 2, 3],
        py: "6px",
        px: "9px",
        // color: "black",
        borderStyle: "outset",
        borderColor: "white",
        borderWidth: "1px",
        fontWeight: "light",
        fontFamily: "Roboto Condensed, sans-serif",
        // bg: tag.color ? tag.color : "#plain",ss
        fontSize: tagTextSizes,
      }}
    >
      <a href={tag.link} style={{
        color: "inherit",
      }}>{tag.name}</a>
    </Box>
  ));
}

class ResearchItem extends React.Component {
  constructor(props) {
    super();
    this.state = { open: props.item.open };
    this.toggleOpen = this.toggleOpen.bind(this);
  }
  toggleOpen() {
    this.setState({ open: !this.state.open });
  }

  render() {
    return (
      // <ThemeProvider theme={theme}>
      <Flex
        sx={{
          pl: [2,2,3],
          // pl: 0,
          // borderColor: "white",
          // borderWidth: "1px",
          ml: 0,
          // color: "#000000",
          pt: 3,
          // bg:'blue',
          height: "auto",
          maxHeight: this.state.open ? 9999 : 450,
          flexBasis: 160,
          transition: "max-height 0.7s ease-in-out",
        }}
      >
        <SEO
          title={this.props.item.title + " " + this.props.item.subtitle}
          description={this.props.item.abstract}
          pathname={"/#" + this.props.item.id}
        />
        <Flex
          sx={{
            // bg: "yellow",
            p: [1, 1, 1],
            // ml: [1, 1, 2],
            pt: [2, 1, 1],
            pb: [1, 2, 3],
            bg: "plainLight",
            fontFamily: "Roboto Condensed, sans-serif",
            flexGrow: 1,
            // color: "plainDark",
            flexDirection: "column",
            borderColor: "white",
            borderWidth: "2px",
            borderStyle: 'solid',
            // ':hover': {
            //   color: 'oran',
            // },
            // borderColor: (this.state.open ? 'oran' : 'plain'),
            // borderWidth: (this.state.open ? '0px' : '0px'),
            mt: 0,
          }}
        >
          <Flex
            sx={{
              //  bg:'yellow',
              px: [2, 2, 2],
              // bg: "plainLight",
              //  borderStyle: 'solid',
              //  borderColor: 'yellow',
              // //  ':hover': {
              // //    color: 'oran',
              // //  },
              //  borderColor: (this.state.open ? 'plainLight' : 'plainLight'),
              //  borderWidth: (this.state.open ? '1px' : '1px'),
              //  pb:0,
              mx: [1, 2, 2],
              mt: [2, 1, 1],
              mb: [1, 2, 2],
              py: [2, 3, "16px"],
              //  py:[3,5,6],
              fontWeight: "bold",
              fontSize: titleTextSizes,
              color: "#000000",
              alignItems: "stretch",
              alignContent: "center",
              //  flexGrow: 999,
              transition: "all 0.3s linear",
            }}
          >
            <Flex
              sx={{
                // bg:'blue',
                minWidth: ["56px", "60px", "64px"],
                flexGrow: 0,
                pt: 0,
                pr: [2, 2, 2],
                pl: [1, 2, 2],
                ml: 0,
                mr: [0, 1, 1],
                borderRightStyle: "solid",
                borderRightColor: "#000000",
                borderRightWidth: ["2px", "2px", "2px"],
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexWrap: true,
              }}
            >
              <Flex>
                <span id={this.props.item.id}>{this.props.item.year}</span>
              </Flex>
              {/* <Box
                sx={{
                  fontSize: ["20px", "20px", "22px"],
                  transition: "all 0.1s ease-in-out",
                  color: "#000000",
                  // color: 'plainDark',
                  // color: 'black',
                  bg: 'red',
                  ":hover": {
                    color: "gree",
                  },
                  pt: ["2pt", "4pt", "4pt"],
                  mb: ["-2pt", "-4pt", "-4pt"],
                }}
              >
                <a
                  href={this.props.item.pdflink}
                  style={{ color: "inherit", textDecoration: "none" }}
                  download
                >
                  <MdPictureAsPdf />
                </a>
              </Box> */}
            </Flex>
            <Flex
              sx={{
                pr: [2, 2, 2],
                pl: [0, 0, 0],
                ml: [2, 1, 1],
                pb: 0,
                mb: 0,
                flexGrow: 99,
                // bg:'red',
                flexDirection: ["column", "column", "row"],
                justifyContent: "space-between",
                aligItems: "center",
                // borderLeftStyle: 'solid',
                // borderLeftColor: 'black',
                // borderLeftWidth: ['2px','2px','2px'],
                // height: '20px',
                // py: 1,
                // width: '65vw',
                // bg: 'oranLight',
              }}
            >
              <Flex
                sx={{
                  // bg:'green',
                  flexGrow: 999,
                  mb: 0,
                  // ml: 2,
                  // mx: 'auto',
                  justifyContent: "space-between",
                  // alignSelf: "stretch",
                  // width: '45vw',
                  whiteSpace: "pre-line",
                  flexDirection: "column",
                }}
              > <Box sx={{
                ":hover": {
                    color: "oran",
                  },
              }} onClick={this.toggleOpen}>
                <Box
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Source Pro, sans-serif",
                  }} 
                >
                  {this.props.item.title}
                </Box>
                <Box
                  sx={{
                    fontWeight: "normal",
                    fontFamily: "Source Pro, sans-serif",
                    fontSize: ["16px", "16px", "18px"],
                    pt: ["1pt", 1, 1],
                    pb: [1, 1, 1],
                  }}
                >
                  {this.props.item.subtitle}
                </Box>
                </Box>
                <Box sx={{
                  py: "6px",
                  px: "9px",
                  color: "#000000",
                  fontFamily: "Roboto Condensed, sans-serif",
                  fontWeight: "light",
                  borderStyle: "outset",
                  borderColor: "white",
                  borderWidth: "1px",
                  // width: "80px",
                  // bg: "#E8F6E0",
                  mt: 2,
                  mb: 1,
                  alignSelf: "flex-start",
                  // ml: 2,
                  // justifySelf: "flex-end",
                  // borderStyle: 'solid',
                  // borderColor: 'plainDark',
                  // borderWidth: "1px",
                  
                  fontSize: tagTextSizes,
                  ":hover": {
                    bg: "#dcf1d0",
                  },
                  display: this.props.item.datavis ? 'inline-block': 'none'
                }}>
                  <Link to={this.props.item.datavisLink} style={{
                    textDecoration: "none",
                    color: "inherit"}}>
                    Data visualization <sup>(beta)</sup></Link>
                </Box>
              </Flex>

              <Flex
                sx={{
                  mt: [2, 2, 1],
                  mx: 2,
                  justifyContent: ["flex-start", "flex-start", "flex-start"],
                }}
              >
                <Box
                  sx={{
                    width: this.props.item.carousel ? "298px" : 0,
                    p: 0,
                    mb: 0,
                    // '@media screen and (max-height: 600px)': {
                    //   opacity: 1,
                    //   width: [220,220,220]
                    // },
                  }}
                >
                  {this.props.item.carousel}
                </Box>
              </Flex>
            </Flex>
          </Flex>
          <Box
            onClick={this.toggleOpen}
            sx={{
              // bg:'yellow',
              px: 4,
              mx: 2,
              //  pt: [2,"16px","24px"],
              flexGrow: 99,
              //  display: (this.state.open ? 'inherit' : 'none'),
              opacity: this.state.open ? 1 : 0,
              maxHeight: this.state.open ? 9999 : 0,
              overflow: "hidden",
              color: "#000000",
              fontSize: ["15px", "15px", "16px"],
              fontFamily: "Roboto Slab, serif",
              lineHeight: "150%",
              transition: "all 0.7s ease-in-out",
            }}
          >
            {this.props.item.abstract}
          </Box>
          <Flex
            sx={{
              p: 1,
              pr: [1, 10, 14],
              //  bg: 'yellow',
              ":hover": {
                color: "oran",
              },
              flexGrow: 1,
              mt: [0, 1, 1],
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                pl: 3,
                pr: 1,
                py:1,
                mt:1,
                opacity: this.state.open ? 0 : 1,
                // color: 'plainDark',
                mx: "auto",
                ml: 1,
                fontWeight: "normal",
                transition: "all 0.3s linear",
                fontSize: smallTextSizes,
              }} onClick={this.toggleOpen}
            >
              <span>&lt;Abstract...&gt;</span>
            </Box>
            <Box>
              <AuthorItems authors={this.props.item.authors}></AuthorItems>
            </Box>
            <Flex sx={{color:'black'}}>
              <PaperTags tags={this.props.item.tags}></PaperTags>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      // </ThemeProvider>
    );
  }
}

class ResearchItems extends React.Component {
  render() {
    return _.map(this.props.items, (item) => (
      <ResearchItem item={item} state={{ open: item.open }} />
    ));
  }
}

// class PaperDetail extends React.Component {
//   state = {
//     index: 0
//   };

//   onToggle = index =>
//     this.setState(state => ({ index: state.index === index ? null : index }));

//   render() {
//     return (
//       <section className="paperDetail">
//        <div className={classNames("item", { "item--active": this.state.index === 1 })}>
//           <button className="btn" onClick={() => this.onToggle(1)}>
//             <span>select 1</span> <span>{this.state.item1}</span>
//           </button>
//           <Collapse
//             className="collapse"
//             isOpen={this.state.index === 1}
//             onChange={({ state }) => {
//               this.setState({ item1: state });
//             }}
//             onInit={({ state }) => {
//               this.setState({ item1: state });
//             }}
//             render={collapseState => createContent(collapseState)}
//           />
//         </div>
//       </section>
//     );
//   }
// }

// function createContent(collapseState) {
//   return (
//     <div className={`content ${collapseState}`}>
//       <p className="text">
//         <span className="state">{collapseState}:</span> Lorem ipsum dolor sit
//         amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
//         labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
//         exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.{" "}
//         <a href="#">link</a>
//       </p>
//       <div className="image-wrapper">
//         <img
//           className="image-wrapper__img"
//           alt="random"
//           src="https://source.unsplash.com/user/erondu/500x200"
//         />
//       </div>
//     </div>
//   );
// }

class ResearchPage extends React.Component {
  constructor(props) {
    super();
    this.state = { myResearch: null };
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      this.setState({
        myResearch: [
          {
            title: "Urban mobility data reveals a trade-off between sustainability and social segregation",
            subtitle:
              "",
            year: 2023,
            id: "15mc",
            authors: [
              {"name": "C. Heine, S. Sabouri, A. Salazar-Miranda, P. Santi, E. Glaeser, and C. Ratti"}
            ],
            pdflink: fmcFile,
            tags: [{ name: "NBER WP",  link: "https://www.nber.org/papers/w30752" }, {name: "PDF",  link: fmcFile}],
            open: 1,
            abstract:
            "To curb transport emissions, policymakers are embracing the idea of local living, suggesting that cities should be planned to accommodate most basic needs within a short walk from home. Previous studies have focused on access to amenities and have not leveraged behavioral data, leaving the feasibility and socio-environmental implications of this approach unaddressed. We introduce a new measure to quantify local trip behavior using GPS data from 40 million mobile devices across the US. We define local usage as the share of trips made within 15 minutes of walking from home and find that the median resident makes only 12% of their daily trips locally. Differences in historic zoning permissiveness within New York suggest a causal link between access and usage. Finally, we quantify a trade-off between the social and environmental goals of local living, showing that local usage is associated with higher experienced segregation for low-income residents."
          },
          {
            title: "The effect of co-location on human communication networks",
            subtitle:
              "",
            year: 2022,
            id: "co-location-email-networks",
            authors: [
              {"name": "Carmody D., Mazzarello M., Santi P., Harris T., Lehmann S., Dunbar R., Ratti C."}
            ],
            pdflink: "https://arxiv.org/abs/2201.02230",
            tags: [{ name: "Nature Comp. Sci.",  link: "https://www.nature.com/articles/s43588-022-00296-z" }, {name: "PDF",  link: "https://arxiv.org/pdf/2201.02230.pdf"}],
            open: 0,
            abstract:
            "The ability to rewire ties in communication networks is vital for large-scale human cooperation and the spread of new ideas. We show that lack of researcher co-location during the COVID-19 lockdown caused the loss of more than 4,800 weak ties—ties between distant parts of the social system that enable the flow of novel information—over 18 months in the email network of a large North American university. Furthermore, we find that the reintroduction of partial co-location through a hybrid work mode led to a partial regeneration of weak ties. We quantify the effect of co-location in forming ties through a model based on physical proximity, which is able to reproduce all empirical observations. Results indicate that employees who are not co-located are less likely to form ties, weakening the spread of information in the workplace. Such findings could contribute to a better understanding of the spatiotemporal dynamics of human communication networks and help organizations that are moving towards the implementation of hybrid work policies to evaluate the minimum amount of in-person interaction necessary for a productive work environment."
          },
          {
            title: "Do local businesses benefit from sports facilities?",
            subtitle:
              "The case of major league sports stadiums and arenas",
            year: 2023,
            id: "stadiums-local-businesses",
            authors: [{ name: "Dmitry Sedov", link: "https://www.dsedov.io/" }],
            pdflink: stadiumsFile,
            tags: [{ name: "Regional Science and Urban Economics",  link: "https://www.sciencedirect.com/science/article/abs/pii/S0166046222000916" }, {name: "PDF",  link: stadiumsFile}],
            open: 0,
            abstract:
            "We use daily mobile foot traffic data covering major league sports facilities and the surrounding commercial establishments in the US to pin down the size and the spatial distribution of foot traffic spillovers from sports facilities to local businesses. By employing the fixed effects and the IV estimation strategies, we show that the spillover benefits are heterogeneous across sports and business sectors. We find that 100 baseball stadium visits generate roughly 29 visits to nearby food & accommodation businesses and about 6 visits to local retail establishments. While the estimates for football stadiums are comparable, basketball & hockey arenas do not appear to generate significant spillovers for the surrounding businesses."
          },
          {
            title: "City change",
            subtitle:
              "The impact of new housing on streetscape diversity in Stockholm",
            year: 2023,
            id: "city-change",
            authors: [
              {"name": "Benson T., Thung Y. X., Duarte F., Ljungqvist L., Ratti C."}
            ],
            pdflink: cityChangeFile,
            tags: [{name: "PDF",  link: cityChangeFile}],
            open: 0,
            abstract:
            "New housing projects often face opposition and controversy due to concerns about altering the character of a neighbourhood and leading to negative cycles of neighbourhood change such as gentrification and displacement. Even though the impact of new housing on the real estate market has been well studied, the effect on commercial diversity is not as well understood. Our research in Stockholm aims to estimate the impact of newly constructed housing on commercial diversity using a difference-in-difference approach that compares 78 housing projects that were planned but ultimately cancelled to those of 1283 that were completed. We analyse individual business data reported to the city from 2007 to 2020 and Google Street View images to assess changes in the languages represented on physical objects such as storefronts and commercial advertisements. Our findings reveal that business diversity increases following the construction of new housing, but language diversity in low-income neighbourhoods decreases. "
          },
          {
            title: "Do urban parks promote racial diversity?",
            subtitle: "Evidence from New York City",
            id: "nyc-parks-diversity",
            year: 2021,
            authors: [],
            datavis: true,
            datavisLink: '/parks/',
            abstract: 
            "In this paper, I provide the first causal evidence to suggest that the provision of urban parks allows the city to promote opportunities for racially and ethnically diverse encounters. Utilizing a novel dataset featuring individual GPS tracking data for more than 60 thousand Twitter users in the New York metro area, I introduce a measure of racial diversity that captures one’s level of exposure to diverse others in places visited daily. My empirical strategy relies on using the variation in the timing of park construction works across the city (which temporarily limit the capacity of said parks) to identify the impact of the effectively accessible parkland area on the individual exposure to racial diversity. My results show that for White and Black/African American residents additional 10 acres of parks within a 5 km radius from home increase individual chances of encounters with the members of other groups by 1 p.p. The effect is sizable: for reference, transitioning from the current state to the random mixing scenario would require a 9 p.p increase in diversity for an average Black or African American individual and a 3.5 p.p increase for an average White person. I also provide evidence to suggest that park accessibility affects the diversity of White and Black residents differently: for parks located closer to home, the effect appears to be more pronounced for Whites than Blacks.",
            pdflink: parksDiversityFile,
            // carousel: <CarouselParks />,
            tags: [
              { name: "Job Market Paper", color: "oranLight", link: parksDiversityFile},
              { name: "PDF" , link: parksDiversityFile},
            ],
            open: 0,
          },
          {
            title: "The role of urban amenities in facilitating social mixing",
            subtitle: 
              "Evidence from Stockholm",
            year: 2023,
            id: "ssl-stockholm",
            authors: [
              {"name": "Heine C., Santi P., Ratti C."}
            ],
            pdflink: sslFile,
            tags: [{name: "PDF",  link: sslFile}],
            open: 0,
            abstract:
            "This study presents new perspectives on analyzing social mixing and socioeconomic integration in cities. We highlight the potential of specific types of amenities to foster diverse social interactions. Our results provide important evidence that certain types of urban spaces host interactions between more income-diverse groups of Stockholm residents; namely, areas of the city with more libraries, educational instutions, healthcare establishments, and restaurants host more exposures between people who are different from one another in terms of income than areas with otherwise-similar amenity distributions. Further, we identify a causal relationship between parks and arts and culture establishments and experienced diversity: temporary, random decreases in access to these spaces due to road closures result in less-diverse encounters for urban residents."
          },
          {
            title: "The contribution of urban parks to social ties between neighborhoods",
            subtitle:
              "A study of online friendship networks in New York City",
            year: 2021,
            id: "parks-social-ties",
            authors: [],
            pdflink: parksSocialTiesFile,
            tags: [{name: "PDF",  link: parksSocialTiesFile}],
            open: 0,
            abstract:
            "Although the role of open spaces in promoting social ties between neighborhoods has been widely discussed by social scientists and urban design scholars, data sets from online social networks present unexplored opportunities to quantify this link on a larger scale. In this paper I use data on friendship links between Facebook users across New York city zip codes to show that two neighborhoods with higher density of green space between them are more likely to have stronger social ties. In particular, when controlling for demographic differences and zip-code level fixed ef- fects, I find that a 1 p.p. increase in the percentage of land allocated to parks between two given zip codes is associated with a 1.2% higher chance of online social connection between their residents. Comparing the effects of park density for different types of parks, I further document that the pres- ence of community parks, flagship parks, and playgrounds are all significant predictors of higher social connectedness between zip codes. Notably, the largest estimated effect is for playgrounds, indicating a 33% higher probability of connection per 1 p.p. increase in density."
          }
        ],
      });
    }
  }
  render() {
    return (
      <Box>
        <span id="research"></span>
        <Box
          sx={{
            px: [0, 2, 2],
            mt: 3,
            // bg: 'red',
            pb: 2,
            pt: 2,
            borderTop: "2px solid",
            borderTopColor: "black",
            fontSize: ["10px", "12px", "15px"],
          }}
        >
          <h1>Research</h1>
          {/* <br/> */}
          <ResearchItems items={this.state.myResearch} />
        </Box>
      </Box>
    );
  }
}

export default ResearchPage;
